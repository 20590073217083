import * as Sentry from "@sentry/remix";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://477467977d07bf8171e5479f33e79054@o127109.ingest.us.sentry.io/4508199832518656",
    tracesSampleRate: 1,
    integrations: [
      Sentry.browserTracingIntegration({
        useLocation,
      }),
    ],
  });
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
