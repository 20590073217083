import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from "@remix-run/react";
import {
  BlockStack,
  Button,
  Card,
  EmptyState,
  Layout,
  Page,
  Text,
} from "@shopify/polaris";
import * as Sentry from "@sentry/remix";
import Logo from "./assets/images/logo.png";
import { AppProvider } from "@shopify/shopify-app-remix/react";
import { IntercomProvider } from "react-use-intercom";
import polarisStyles from "@shopify/polaris/build/esm/styles.css?url";

export const links = () => [{ rel: "stylesheet", href: polarisStyles }];

export default function App() {
  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="preconnect" href="https://cdn.shopify.com/" />
        <link
          rel="stylesheet"
          href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css"
        />
        <Meta />
        <Links />
      </head>
      <body>
        <IntercomProvider appId="vlh5nk1z">
          <Outlet />
        </IntercomProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  Sentry.captureRemixErrorBoundaryError(error);
  return (
    <html>
      <head>
        <title>Something went wrong</title>
        <Meta />
        <Links />
      </head>
      <body>
        <AppProvider>
          <Page>
            <Layout>
              <Layout.Section>
                {error.status === 404 ? (
                  <NotFound error={error} />
                ) : (
                  <Card>
                    <EmptyState
                      heading="Something went wrong"
                      action={{ content: "Home", url: "/app" }}
                      image={Logo}
                    ></EmptyState>
                  </Card>
                )}
              </Layout.Section>
            </Layout>
          </Page>
        </AppProvider>
        <Scripts />
      </body>
    </html>
  );
};

function NotFound({ error }) {
  return (
    <Card>
      <BlockStack inlineAlign="center" gap={"400"}>
        <img src={Logo} alt="Logo" />
        <Text variant="headingLg">Page Not Found</Text>
        <Button url="/app" variant="primary" size="large">
          Home
        </Button>
      </BlockStack>
      {process.env.NODE_ENV === "development" && <pre>{error.stack}</pre>}
    </Card>
  );
}
